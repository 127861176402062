import HOT16EN from '../components/hot16/hot16-en'
import React from 'react'
import Hot16Logo from '../components/hot16/Details/Hot16Logo'

export default () => (
  <div>
    <div style={{ position: 'absolute' }}>
      <Hot16Logo
        style={{
          pointerEvents: 'none',
          marginTop: 20,
          //   zIndex: 666,
          //   opacity: currentStep.noLogo ? 0.1 : 1,
        }}
      />
    </div>
    <HOT16EN />
  </div>
)
